<template>
  <b-card title="List Word Blacklist">
    <chat-list-word-blacklist />
    <chat-moderator-sdk ref="sdk" />

  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
 async mounted() {
    await customElements.whenDefined('chat-moderator-sdk')
    const elem = document.querySelector('chat-moderator-sdk')

    const url = config.VUE_CONFIG_SIGNALR
    const data = {
      partnerId: 'MODERATORS',
      userId: window.localStorage.getItem('userId'),
      name: window.localStorage.getItem('username'),
      currencyCode: 'R$',
      languageCode: 'BRL',
      userType: 2,
    }

    const response = await fetch(`${url}/player/login`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })

    const information = await response.json()
    console.log(information)
    // const parentId = 'TEST'

    await elem.configure({
      AppId: data.partnerId,
      TokenId: information.tokenId,
      SessionId: information.sessionId,
      SignalRUrl: url,
    })
  },   
}
</script>

<style>

</style>
